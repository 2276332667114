<template>
  <div>
    <p class="h4 pt-3 pb-4">汇率设置</p>
    <el-form ref="exchangeForm" :model="exchangeForm" label-width="100px" :rules="rules" style="max-width: 400px">
      <el-form-item label="当前汇率" prop="exchange">
        <el-input v-model="exchangeForm.exchange" placeholder="汇率"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="success" @click="update">更新</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { getExchange, postExchange } from '@/api/points'
export default {
  name: 'exchange',
  data () {
    return {
      exchangeForm: {
        exchange: 0
      },
      rules: {
        exchange: [
          { required: true, message: '请输入', trigger: 'blur' }
        ]
      }
    }
  },
  created () {
    getExchange().then(
      res => {
        this.exchangeForm.exchange = res.data.exchange
      }
    ).catch(
      err => {
        console.log(err)
      }
    )
  },
  methods: {
    update () {
      this.$refs.exchangeForm.validate(
        valid => {
          if (valid) {
            postExchange({
              exchange: Number(this.exchangeForm.exchange)
            }).then(
              res => {
                console.log('update')
                this.$notify.success({
                  title: '成功',
                  message: '更新成功'
                })
              }
            ).catch(
              err => {
                console.log(err)
              }
            )
          }
        }
      )
    }
  }
}
</script>
<style>
</style>
